import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { DISTRICT } from '~/helpers/constants/ElectorateTypes';
import { getInstructionsForElection } from '~/helpers/BallotHelper';
import { Instructions } from '~/helpers/types/Instructions';

// const Heading = styled.div`
//   text-align: center;
//   font-size: 16px;
//   text-transform: uppercase;
//   margin-bottom: 10px;
// `;
//
// const House = styled.span`
//   font-weight: bold;
// `;


type BallotHeaderProps = {
  /** The Greens candidate on this ballot.
   * Only used to check what type of electorate this ballot is for.
   */
  candidate: Instructions;
  /** The code for this election.
   * Using this is tech debt that must be addressed.
   */
  electionCode: string;
};

/**
 * The header at the top of a ballot.
 */
const BallotHeader: React.SFC<BallotHeaderProps> = ({ candidate, electionCode }) => {
  const InstructionBox = styled.div.attrs({ className: electionCode === 'fed19' && candidate.electorateType !== DISTRICT ? 'u-bg-green-fun' : 'u-bg-white' })`
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    margin-bottom: 25px;
    ${electionCode === 'fed19' && candidate.electorateType !== DISTRICT && 'color: white;'} ${electionCode === 'fed19'
        && candidate.electorateType !== DISTRICT
        && 'font-weight: bold;'} padding: 5px;
    font-size: ${electionCode === 'fed19' && candidate.electorateType !== DISTRICT ? '20px' : '15px'};
  `;

  return (
    <Container>
      <InstructionBox>{getInstructionsForElection(candidate, electionCode)}</InstructionBox>
    </Container>
  );
};

export default BallotHeader;
