import { groupBy } from 'lodash';
import { urlSafeElectorate } from '~/helpers/constants/Electorates';
import { Config } from '~/helpers/types/Config';
import { Election } from '~/helpers/types/Election';
import { Electorate } from '~/helpers/types/Electorate';

const CommonHeaders = {
  'Content-Type': 'application/json',
};

const endpoints = {
  electorateLookup: '/electorate',
  preferences: '/preferences',
  candidate: '/candidate',
  booths: '/booths',
  packet: '/packet',
};

const getApiConfig = ({ env, election, prodDomain }: { env?: string; election?: Election; prodDomain?: string}) => {
  let base;
  if (env === 'prod' && prodDomain) {
    base = `https://api.${prodDomain}`;
  } else if (env === 'local') {
    base = 'http://localhost:4000';
  } else {
    base = `https://api.${election.code}.htv.${env}.greens.systems`;
  }
  return {
    base,
    endpoints,
  };
};

/* TODO: Proper error handling */

const getElectorates = async (config: Config, latitude: string, longitude: string) => {
  const api = getApiConfig(config);
  const endpoint = `${api.base}${api.endpoints.electorateLookup}`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      ...CommonHeaders,
    },
    body: JSON.stringify({ lat: latitude, lng: longitude }),
  });
  const body = await response.json();
  return body;
};

const getPreferences = async (config: Config, authToken?: string) => {
  const api = getApiConfig(config);
  const endpoint = `${api.base}${api.endpoints.preferences}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      ...CommonHeaders,
      Authorization: authToken ? authToken : undefined,
    },
  });
  const body = await response.json();
  const preferences = groupBy(body, 'electorate');
  return preferences;
};

const getCandidateInfo = async (config: Config, electorate: Electorate) => {
  const api = getApiConfig(config);
  const electorateNameToGet = config.candidateUrlOverrides[electorate.name] || electorate.name;
  const endpoint = `${api.base}${api.endpoints.candidate}/${urlSafeElectorate(electorateNameToGet)}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      ...CommonHeaders,
    },
  });
  try {
    const body = await response.json();
    return body;
  } catch (err) {
    return null;
  }
};

const getBooths = async (config: Config, electorate: Electorate) => {
  const api = getApiConfig(config);
  const endpoint = `${api.base}${api.endpoints.booths}/${electorate.name}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      ...CommonHeaders,
    },
  });
  try {
    const body = await response.json();
    return body;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const postPacket = async (config: Config, firstName: string, lastName: string, email: string, postcode: string) => {
  const api = getApiConfig(config);
  const endpoint = `${api.base}${api.endpoints.packet}`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      ...CommonHeaders,
    },
    body: JSON.stringify({
      firstName, lastName, email, postcode,
    }),
  });
  const body = await response;
  return body;
};


const ApiClient = {
  getElectorates,
  getPreferences,
  getCandidateInfo,
  getBooths,
  postPacket,
};

export default ApiClient;
