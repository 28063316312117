import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Config } from '~/helpers/types/Config';
import Shapes from '~/helpers/shapes/Shapes';
import { REGION, STATE } from '~/helpers/constants/ElectorateTypes';
import { getBallotColour } from '~/helpers/BallotHelper';
import BallotHeader from '~/components/sections/HowToVote/Ballot/BallotHeader/BallotHeader';
import Preference from '~/components/sections/HowToVote/Ballot/Preference/Preference';
import UpperHousePreference from '~/components/sections/HowToVote/Ballot/UpperHousePreference/UpperHousePreference';
import { Electorate } from '~/helpers/types/Electorate';

type BallotProps = {
  /** Array of preferences for the ballot paper. */
  preferences: (any | any)[];
  /** Details of the election. */
  config: Config;
  /** Should the ballot be aligned to the right? */
  alignRight?: boolean;
  /** Electorate the ballot paper is for. */
  electorate: Electorate;
};

type InnerBallotProps = {
  electorateType: string;
};

/**
 * A ballot paper with all preferences.
 * Can be an upper ballot or lower ballot depending on the ElectorateType
 * of the preferences.
 */
const Ballot: React.SFC<BallotProps> = ({ preferences, config, alignRight, electorate }) => {
  const sortedPreferences = preferences.sort((p1, p2) => {
    if (p1.position) {
      return p1.position - p2.position;
    }
    return p1.group.length - p2.group.length || p1.group.localeCompare(p2.group);
  });
  /* eslint-disable max-len */
  const greensPreference = preferences.find((p) => config.partyNameOnBallot.includes(p.party));
  const greensCandidate = {
    ...greensPreference,
    electorate,
  };
  const {
    showGreensLogoOnBallot,
    showGroupLabelOnBallot,
    capitaliseUpperHouseParties,
    upperBallotLogoPosition,
    boldGreensCandidate,
    partyNameOnBallot,
    linkCandidateOnBallot,
  } = config;
  const ballotColour = getBallotColour(electorate.type, electorate, config);
  const InnerBallot = styled.div<InnerBallotProps>`
    border: 2px solid black;
    padding: 15px;
    max-width: ${(props) => (props.electorateType === STATE ? '1000px' : '350px')};
    margin-left: auto;
    margin-right: auto;
  `;
  let candidates;
  if (electorate.type === REGION) {
    candidates = (
      <UpperHousePreference
        {...greensPreference}
        showGreensLogoOnBallot={showGreensLogoOnBallot}
        partyNameOnBallot={partyNameOnBallot}
      />
    );
  } else if (electorate.type === STATE) {
    candidates = sortedPreferences.map((pref) => (
      <Col md={2} style={{ paddingLeft: '2px', paddingRight: '2px', paddingBottom: '5px' }}>
        <UpperHousePreference
          key={pref.position}
          showGreensLogoOnBallot={showGreensLogoOnBallot}
          showGroupLabelOnBallot={showGroupLabelOnBallot}
          capitaliseUpperHouseParties={capitaliseUpperHouseParties}
          upperBallotLogoPosition={upperBallotLogoPosition}
          partyNameOnBallot={partyNameOnBallot}
          {...pref}
        />
      </Col>
    ));
  } else {
    candidates = sortedPreferences.map((pref) => (
      <Preference
        key={pref.position}
        electorateName={electorate.name}
        showGreensLogoOnBallot={showGreensLogoOnBallot}
        boldGreensCandidate={boldGreensCandidate}
        partyNameOnBallot={partyNameOnBallot}
        linkCandidateOnBallot={linkCandidateOnBallot}
        {...pref}
      />
    ));
  }
  return (
    <Container style={{padding: 0}}>
      <Row>
        <InnerBallot electorateType={electorate.type} style={{ backgroundColor: ballotColour }}>
          <Row>
            <BallotHeader candidate={greensCandidate} electionCode={config.election.code} />
          </Row>
          <Row>{candidates}</Row>
          {electorate.type === 'State'
          && (
          <Row>
            <Col>
              <div><strong>DO NOT complete the boxes below the line.</strong></div>
            </Col>
          </Row>
          )}
        </InnerBallot>
      </Row>
    </Container>
  );
};

export default Ballot;
