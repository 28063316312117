import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { actions, connect } from '~/store';
import withElectorates from '~/helpers/providers/withElectorates';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import Button from '@material-ui/core/Button';

/** Greens styled Material UI AppBar. */
const Bar = styled(AppBar).attrs({ className: 'u-bg-green-camarone' })`
  background-color: #005f27 !important;
  border-bottom: 2px solid white;
  min-height: 48px;
`;

/** Styled Material UI Typography wrapper for text in the bar. */
const Text = styled(Typography)`
  font-weight: bold !important;
  margin-left: 10px !important;
  margin-top: auto;
  margin-bottom: auto;
`;

/** Styled back icon, used to indicate that the user can tap the bar to change electorate. */
const Icon = styled.i.attrs({ className: 'fa fa-angle-left fa-2x' })`
  vertical-align: -6px;
`;

/** Styled span containing the lower house electorate name, if any. */
const Electorate = styled.span`
  padding-left: 10px;
  text-transform: uppercase;
`;

type DevToolsProps = {
  /** The currently selected electorates, if any. */
  selectedElectorates?: SelectedElectorates;
  electorates?: any;
};

/**
 * The header that appears at the top of the Mobile HTV app.
 * Wrapped and styled version of Material UI AppBar.
 */
const DevTools: React.SFC<DevToolsProps> = (props) => {
  const { electorates, selectedElectorates } = props;
  const [hidden, hide] = useState(false);
  const nextElectorate = () => {
    const index = electorates.lower.findIndex(e => e === selectedElectorates.lower.name);
    actions.setElectoratesByLower(electorates.lower[index+1]);
    actions.getCandidateInfo();
  }
  return (
    <div style={{margin: '10px', padding: '10px', border: '2px solid red', position: 'absolute', display: hidden ? 'none' : 'block'}}>
      <h2>Developer tools</h2>
      <p><b>Current:</b> {selectedElectorates?.lower?.name}</p>
      <button className="btn btn-secondary" onClick={() => hide(true)}>Hide</button>
      <button className="btn btn-primary" onClick={() => nextElectorate()}>Next</button>
    </div>
  );
};

DevTools.defaultProps = {
  electorates: null,
};

export default connect((state) => ({
  electorates: state.config.electorates,
  selectedElectorates: state.electorates,
}))(DevTools)