/**
 * Originally intended to be constants with details about each election.
 * Instead became an awful mess containing all election-specific configuration.
 * Needs major work.
 */

/* eslint-disable  */
import React, { Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { STATE, FEDERAL } from '~/helpers/constants/ElectionTypes';
import { WA, VIC, SA, NSW } from '~/helpers/constants/States';
import {
  VictorianStateDistricts,
  VictorianStateRegions,
  VictorianStateRegionsByDistrict,
  NswStateDistricts,
  FederalDistricts,
  FederalStates,
  FederalDistrictsByState,
  QueenslandCouncilWards,
  QueenslandExtraBallots,
  QueenslandCouncilWardsToExtraBallot,
  QueenslandStateDivisions,
  SaStateDistricts,
  WaStateDistricts,
  WaStateRegions,
  WaStateRegionsByDistrict,
} from '~/helpers/constants/Electorates';
import { daysUntil } from '~/helpers/DateHelper';
import { Issues, PopGrid } from '@australiangreens/components';
import Fed19WVG from '~/components/sections/WhyVoteGreen/Fed19WVG/Fed19WVG';
import Qld20sWVG from '~/components/sections/WhyVoteGreen/Qld20sWVG/Qld20sWVG';
import Wa21WVG from '~/components/sections/WhyVoteGreen/Wa21WVG/Wa21WVG';
import Fed22WVG from '~/components/sections/WhyVoteGreen/Fed22WVG/Fed22WVG';
import { Election } from '~/helpers/types/Election';
import { Config } from '~/helpers/types/Config';
import ElectionIssues from '~/helpers/constants/ElectionIssues';
import { REGION, STATE as StateElectorate, DISTRICT } from '~/helpers/constants/ElectorateTypes';
import Vic18Booths from '../../assets/data/vic18_booths.json';
import Nsw19Booths from '../../assets/data/nsw19_booths.json';
import Sa22Booths from '../../assets/data/sa22_booths.json';

export const VIC18: Config = {
  election: {
    code: 'vic18',
    name: '2018 Victorian State Election',
    type: STATE,
    state: VIC,
    electionDay: new Date('2018-11-24'),
    pollsAreClosed: true,
  },
  partyNameOnBallot: ['AUSTRALIAN GREENS'],
  showGreensLogoOnBallot: true,
  getCandidateInfo: true,
  electorates: {
    lower: VictorianStateDistricts,
    upper: VictorianStateRegions,
    upperType: REGION,
    lowerToUpper: lower => VictorianStateRegionsByDistrict[lower]
  },
  defaultLocation: {
    lat: -37.8136,
    lng: 144.9631
  },
  authorisationLine: 'Authorised by C. Quinn, 1/45 William St, Melbourne VIC 3000',
  // whyVoteGreenContent: <Issues issues={ElectionIssues.vic18} />,
  featureToggles: {}
};

export const NSW19: Config = {
  election: {
    code: 'nsw19',
    name: '2019 New South Wales State Election',
    type: STATE,
    state: NSW,
    electionDay: new Date('2019-03-23'),
    pollsAreClosed: true,
  },
  partyNameOnBallot: ['THE GREENS'],
  showGreensLogoOnBallot: false,
  showGroupLabelOnBallot: true,
  getCandidateInfo: false,
  electorates: {
    lower: NswStateDistricts,
    upper: ['NSW'],
    upperType: StateElectorate,
    lowerToUpper: (lower) => 'NSW'
  },
  defaultLocation: {
    lat: -33.8688,
    lng: 151.2093
  },
  authorisationLine: 'Authorised by A Blake, The Greens NSW, Suite D, Level 1, 275 Broadway Glebe NSW 2037',
  // whyVoteGreenContent: <PopGrid tiles={ElectionIssues.nsw19} rowSize={3} />,
  featureToggles: {}
};

export const FED19: Config = {
  election: {
    code: 'nsw19',
    name: '2019 New South Wales State Election',
    type: STATE,
    state: NSW,
    electionDay: new Date('2019-05-18'),
    pollsAreClosed: true,
  },
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['The Greens', 'The Greens (VIC)', 'The Greens (WA)'],
  showGreensLogoOnBallot: false,
  upperBallotLogoPosition: 'above',
  getCandidateInfo: false,
  showUpperFirst: true,
  lowerBallotColour: '#EEF8E8',
  lowerBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote 1 for Climate Action</Hidden>
      <Hidden smDown>
        How to vote in<br />the House of Representatives
      </Hidden>
    </Fragment>
  ),
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote 1 for Climate Action</Hidden>
      <Hidden smDown>
        Put the Greens first<br />in the Senate
      </Hidden>
    </Fragment>
  ),
  headerTitle: 'Vote 1 for Climate Action',
  showGroupLabelOnBallot: false,
  capitaliseUpperHouseParties: true,
  linkCandidateOnBallot: true,
  electorates: {
    lower: FederalDistricts,
    upper: FederalStates,
    upperType: StateElectorate,
    lowerToUpper: lower => FederalDistrictsByState[lower]
  },
  groupElectoratesOnSelect: true,
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by Willisa Hogarth and Catherine Garner, The Greens, 2/97 Northbourne Avenue Turner ACT',
  whyVoteGreenContent: <Fed19WVG />,
  easyEnglishGuideUrl: new URL('https://contattafiles.s3.us-west-1.amazonaws.com/tnt34836/L92VHqYk5em5jwK/How%20to%20vote%20Greens_Easy%20English_web%20%5BAccessible%5D.pdf'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Together, we're building a future for all of us. Find out how to vote Greens in the upcoming federal election, and more about your local candidate.",
  featureToggles: {},
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au') },
    { title: 'Contact us', url: new URL('https://greens.org.au/contact') }
  ],
  showOverseasInfo: true
};

export const QLD20: Config = {
  election: {
    code: 'qld20',
    name: '2020 Queensland Local Elections',
    electionDay: new Date('2020-03-28'),
    pollsAreClosed: true,
    type: STATE,
  },
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['The Greens'],
  showGreensLogoOnBallot: false,
  getCandidateInfo: false,
  lowerBallotColour: '#CDEBB5',
  upperBallotColour: '#F0F0FA',
  showUpperFirst: true,
  headerTitle: 'A Future For All Of Us',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: QueenslandCouncilWards,
    upper: QueenslandExtraBallots,
    lowerToUpper: lower => QueenslandCouncilWardsToExtraBallot[lower]
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 102 McDonald Road, Windsor 4030',
  whyVoteGreenContent: (
    <div>
      <p>
        It’s an important time in Queensland. We have multiple elections happening on 28 March: the Council elections and two by-elections in Bundamba and Currumbin.
        Early voting has started and many Queenslanders have already made their voice heard.{' '}
      </p>
      <p>At the moment it seems like politics is completely disconnected from the things we actually care about. We can change this.</p>
      <p>This is your chance to have your say, to tell your representatives what you want for the place where you live. </p>
      <p>We believe everyone deserves equal opportunities and the right to a good life.</p>
      <p>
        Our candidates and members have been out in the community, listening to the concerns of local residents. We have heard the call for cities and towns that are
        more affordable, more accessible and greener.
      </p>
      <p>
        Right now, our cities and towns are being shaped by politicians who receive donations from big corporates and rich developers. That has led to
        over-development, high prices, ineffective waste management systems, traffic chaos, unsafe roads, expensive, unreliable public transport and a lack of green
        space.
      </p>
      <p>We do not take donations from big corporates and rich developers like the other parties. That means we govern for you – not donors and vested interests.</p>
      <p>We want decisions about planning and the future of our cities and towns to be put back into the hands of the people who live there – you.</p>
      <p>
        A vote for the Greens is a vote for:
        <ul>
          <li>affordable housing</li>
          <li>cheap, accessible public transport</li>
          <li>safer roads and more crossings</li>
          <li>ways to help shops and small businesses thrive</li>
          <li>limits on development</li>
          <li>effective ways to recycle and manage waste</li>
          <li>action on the climate emergency</li>
          <li>more green space</li>
        </ul>
      </p>
      <p>Together, we can make it happen. These elections, vote 1 Greens. </p>
    </div>
  ),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'With more Greens on Council, we can make sure our communities and your needs come first.',
  featureToggles: {},
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/qld') }
  ],
  labels: {
    lowerLabel: 'Local Council',
    upperLabel: ''
  },
  electionHasSausage: false
};

export const QLD20S: Config = {
  election: {
    code: 'qld20s',
    name: '2020 Queensland State Election',
    electionDay: new Date('2020-10-31'),
    pollsAreClosed: true,
    type: STATE,
  },
  pollsAreClosed: true,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['Queensland Greens'],
  showGreensLogoOnBallot: false,
  getCandidateInfo: true,
  showCandidateHero: true,
  lowerBallotColour: '#CDEBB5',
  upperBallotColour: '#F0F0FA',
  showUpperFirst: false,
  headerTitle: 'How To Vote Greens...',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: QueenslandStateDivisions,
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 102 McDonald Road, Windsor 4030',
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'Vote [1] Greens for a future for all of us.',
  featureToggles: {},
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/qld') }
  ],
  labels: {
    lowerLabel: 'Division',
    upperLabel: undefined
  },
  electionHasSausage: true,
  hideHowToVoteInstructions: true,
};

export const WA21: Config = {
  election: {
    code: 'wa21',
    name: '2021 WA State Election',
    electionDay: new Date('2021-03-13'),
    pollsAreClosed: true,
    type: STATE,
    state: WA,
  },
  pollsAreClosed: false,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['THE GREENS (WA)'],
  showGreensLogoOnBallot: false,
  getCandidateInfo: false,
  showCandidateHero: false,
  lowerBallotColour: '#ffffff',
  showUpperFirst: true,
  headerTitle: 'Vote Greens for Climate Action',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: WaStateDistricts,
    upper: WaStateRegions,
    upperType: REGION,
    lowerToUpper: lower => WaStateRegionsByDistrict[lower],
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -24.1448,
    lng: 111.8624
  },
  authorisationLine: 'Authorised by Emma Pringle & Gareth Doogue, The Greens (WA), 91/215 Stirling St, Perth 6000',
  whyVoteGreenContent: <Wa21WVG />,
  easyEnglishGuideUrl: new URL('https://greens.org.au/sites/default/files/2021-02/wa2021-htv-greens-easyenglish.pdf'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'Vote [1] Greens for a future for all of us.',
  featureToggles: {},
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/wa') }
  ],
  labels: {
    lowerLabel: 'Lower House',
    upperLabel: 'Upper House'
  },
  electionHasSausage: true,
  hideHowToVoteInstructions: false,
};

export const SA22: Config = {
  election: {
    code: "sa22",
    name: "2022 SA State Election",
    electionDay: new Date("2022-03-19"),
    type: STATE,
    state: SA,
    pollsAreClosed: false, // not actually used but required for type compliance
  },
  pollsAreClosed: false,
  prodDomain: "vote.greens.org.au",
  partyNameOnBallot: ["The Greens"],
  showGreensLogoOnBallot: false,
  getCandidateInfo: false,
  showCandidateHero: false,
  lowerBallotColour: "#cfe8ca",
  showUpperFirst: true,
  headerTitle: "Vote Greens for Climate Action",
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: SaStateDistricts,
    upper: ["SA"],
    upperType: StateElectorate,
    lowerToUpper: (lower) => "SA",
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -34.9979,
    lng: 138.5122,
  },
  authorisationLine:
    "Authorised by I. Green, The Greens, 7/291 Angas Street, Adelaide SA 5000",
  // whyVoteGreenContent: <Sa22WVG />,
  shareUrl: new URL("https://vote.greens.org.au"),
  shareText: "Vote [1] Greens for climate action.",
  featureToggles: {},
  footerLinks: [
    { title: "Privacy", url: new URL("https://greens.org.au/privacy") },
    { title: "Main website", url: new URL("https://greens.org.au/sa") },
  ],
  labels: {
    lowerLabel: "House of Assembly",
    upperLabel: "Legislative Council",
  },
  electionHasSausage: false,
  hideHowToVoteInstructions: true,
};

export const FED22: Config = {
  election: {
    code: 'fed22',
    name: '2022 Federal Election',
    type: FEDERAL,
    electionDay: new Date('2022-05-21'),
    pollsAreClosed: true,
  },
  electionHasSausage: true,
  pollsAreClosed: true,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['Australian Greens', 'The Greens NSW', 'Queensland Greens', 'The Australian Greens - Victoria', 'The Greens (WA) Inc', 'The Greens (WA)', 'The Greens'],
  showGreensLogoOnBallot: false,
  upperBallotLogoPosition: 'above',
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  showCandidateHero: false,
  showUpperFirst: true,
  lowerBallotColour: '#EEF8E8',
  lowerBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens</Hidden>
      <Hidden smDown>
        How to vote in the House
      </Hidden>
    </Fragment>
  ),
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens </Hidden>
      <Hidden smDown>
        How to vote in the Senate
      </Hidden>
    </Fragment>
  ),
  headerTitle: 'Vote Climate, Vote 1 Greens',
  showGroupLabelOnBallot: false,
  capitaliseUpperHouseParties: true,
  linkCandidateOnBallot: true,
  electorates: {
    lower: FederalDistricts,
    upper: FederalStates,
    lowerType: DISTRICT,
    upperType: StateElectorate,
    lowerToUpper: lower => FederalDistrictsByState[lower]
  },
  groupElectoratesOnSelect: true,
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by Matthew Roberts and Willisa Osburn, The Greens, 2/18 Lonsdale Street Braddon ACT 2612',
  whyVoteGreenContent: <Fed22WVG />,
  easyEnglishGuideUrl: new URL('https://greens.org.au/easy-to-read'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election. Your vote can help kick the Libs out and give the Greens the power to get real action on the climate crisis and dental and mental health into Medicare 💚",
  featureToggles: {},
  footerLinks: [
    { title: 'Translations', url: new URL('https://greens.org.au/in-language') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main site', url: new URL('https://greens.org.au') },
  ],
  showOverseasInfo: true,
  candidateUrlOverrides: {
    'VIC': 'lidia-thorpe',
    'QLD': 'penny-allman-payne',
    'TAS': 'peter-whish-wilson',
    'WA': 'dorinda-cox',
    'ACT': 'dr-tjanara-goreng-goreng',
    'NT': 'jane-anlezark',
    'SA': 'barbara-pocock',
    'NSW': 'david-shoebridge'
  },
  upperBallotOverrides: {
    "Banks": "NSW-ALT",
    "Bennelong": "NSW-ALT",
    "Berowra": "NSW-ALT",
    "Bradfield": "NSW-ALT",
    "Calare": "NSW-ALT",
    "Cook": "NSW-ALT",
    "Cowper": "NSW-ALT",
    "Dobell": "NSW-ALT",
    "Farrer": "NSW-ALT",
    "Hughes": "NSW-ALT",
    "Hume": "NSW-ALT",
    "Lindsay": "NSW-ALT",
    "Lyne": "NSW-ALT",
    "Mackellar": "NSW-ALT",
    "Mitchell": "NSW-ALT",
    "New England": "NSW-ALT",
    "North Sydney": "NSW-ALT",
    "Page": "NSW-ALT",
    "Parkes": "NSW-ALT",
    "Richmond": "NSW-ALT",
    "Riverina": "NSW-ALT",
    "Robertson": "NSW-ALT",
    "Warringah": "NSW-ALT",
    "Wentworth": "NSW-ALT",
  }
};
export default {
  VIC18,
  NSW19,
  FED19,
  QLD20,
  QLD20S,
  WA21,
  SA22,
  FED22
};
