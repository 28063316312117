/**
 * Originally intended to just be the names of electorates.
 * Also contains helper functions for mapping lower-to-upper,
 * helpers for normalising/formatting electorate names,
 * and electorate-specific configuration overrides.
 */
/* eslint-disable quote-props, quotes, react/jsx-no-target-blank, react/prop-types */
import React from 'react';
import { findIndex } from 'lodash';
import { Container } from 'react-bootstrap';
import { generatePoints } from '~/components/sections/WhyVoteGreen/Fed19WVG/Fed19WVG';
import { Electorate } from '../types/Electorate';

export const VictorianStateDistricts = [
  'Albert Park',
  'Altona',
  'Bass',
  'Bayswater',
  'Bellarine',
  'Benambra',
  'Bendigo East',
  'Bendigo West',
  'Bentleigh',
  'Box Hill',
  'Brighton',
  'Broadmeadows',
  'Brunswick',
  'Bulleen',
  'Bundoora',
  'Buninyong',
  'Burwood',
  'Carrum',
  'Caulfield',
  'Clarinda',
  'Cranbourne',
  'Croydon',
  'Dandenong',
  'Eildon',
  'Eltham',
  'Essendon',
  'Euroa',
  'Evelyn',
  'Ferntree Gully',
  'Footscray',
  'Forest Hill',
  'Frankston',
  'Geelong',
  'Gembrook',
  'Gippsland East',
  'Gippsland South',
  'Hastings',
  'Hawthorn',
  'Ivanhoe',
  'Kew',
  'Keysborough',
  'Kororoit',
  'Lara',
  'Lowan',
  'Macedon',
  'Malvern',
  'Melbourne',
  'Melton',
  'Mildura',
  'Mill Park',
  'Monbulk',
  'Mordialloc',
  'Mornington',
  'Morwell',
  'Mount Waverley',
  'Mulgrave',
  'Murray Plains',
  'Narracan',
  'Narre Warren North',
  'Narre Warren South',
  'Nepean',
  'Niddrie',
  'Northcote',
  'Oakleigh',
  'Ovens Valley',
  'Pascoe Vale',
  'Polwarth',
  'Prahran',
  'Preston',
  'Richmond',
  'Ringwood',
  'Ripon',
  'Rowville',
  'Sandringham',
  'Shepparton',
  'South Barwon',
  'South-West Coast',
  'St Albans',
  'Sunbury',
  'Sydenham',
  'Tarneit',
  'Thomastown',
  'Warrandyte',
  'Wendouree',
  'Werribee',
  'Williamstown',
  'Yan Yean',
  'Yuroke',
];
export const VictorianStateRegions = ['Eastern Metropolitan', 'Eastern Victoria', 'Northern Metropolitan', 'Northern Victoria', 'South Eastern Metropolitan', 'Southern Metropolitan', 'Western Metropolitan', 'Western Victoria'];
export const VictorianStateRegionsByDistrict = {
  Bayswater: 'Eastern Metropolitan',
  'Box Hill': 'Eastern Metropolitan',
  Bulleen: 'Eastern Metropolitan',
  Croydon: 'Eastern Metropolitan',
  Eltham: 'Eastern Metropolitan',
  'Ferntree Gully': 'Eastern Metropolitan',
  'Forest Hill': 'Eastern Metropolitan',
  Ivanhoe: 'Eastern Metropolitan',
  'Mount Waverley': 'Eastern Metropolitan',
  Ringwood: 'Eastern Metropolitan',
  Warrandyte: 'Eastern Metropolitan',
  Bass: 'Eastern Victoria',
  Evelyn: 'Eastern Victoria',
  Gembrook: 'Eastern Victoria',
  'Gippsland East': 'Eastern Victoria',
  'Gippsland South': 'Eastern Victoria',
  Hastings: 'Eastern Victoria',
  Monbulk: 'Eastern Victoria',
  Mornington: 'Eastern Victoria',
  Morwell: 'Eastern Victoria',
  Narracan: 'Eastern Victoria',
  Nepean: 'Eastern Victoria',
  Broadmeadows: 'Northern Metropolitan',
  Brunswick: 'Northern Metropolitan',
  Bundoora: 'Northern Metropolitan',
  Melbourne: 'Northern Metropolitan',
  'Mill Park': 'Northern Metropolitan',
  Northcote: 'Northern Metropolitan',
  'Pascoe Vale': 'Northern Metropolitan',
  Preston: 'Northern Metropolitan',
  Richmond: 'Northern Metropolitan',
  Thomastown: 'Northern Metropolitan',
  Yuroke: 'Northern Metropolitan',
  Benambra: 'Northern Victoria',
  'Bendigo East': 'Northern Victoria',
  'Bendigo West': 'Northern Victoria',
  Eildon: 'Northern Victoria',
  Euroa: 'Northern Victoria',
  Macedon: 'Northern Victoria',
  Mildura: 'Northern Victoria',
  'Murray Plains': 'Northern Victoria',
  'Ovens Valley': 'Northern Victoria',
  Shepparton: 'Northern Victoria',
  'Yan Yean': 'Northern Victoria',
  Carrum: 'South Eastern Metropolitan',
  Clarinda: 'South Eastern Metropolitan',
  Cranbourne: 'South Eastern Metropolitan',
  Dandenong: 'South Eastern Metropolitan',
  Frankston: 'South Eastern Metropolitan',
  Keysborough: 'South Eastern Metropolitan',
  Mordialloc: 'South Eastern Metropolitan',
  Mulgrave: 'South Eastern Metropolitan',
  'Narre Warren North': 'South Eastern Metropolitan',
  'Narre Warren South': 'South Eastern Metropolitan',
  Rowville: 'South Eastern Metropolitan',
  'Albert Park': 'Southern Metropolitan',
  Bentleigh: 'Southern Metropolitan',
  Brighton: 'Southern Metropolitan',
  Burwood: 'Southern Metropolitan',
  Caulfield: 'Southern Metropolitan',
  Hawthorn: 'Southern Metropolitan',
  Kew: 'Southern Metropolitan',
  Malvern: 'Southern Metropolitan',
  Oakleigh: 'Southern Metropolitan',
  Prahran: 'Southern Metropolitan',
  Sandringham: 'Southern Metropolitan',
  Altona: 'Western Metropolitan',
  Essendon: 'Western Metropolitan',
  Footscray: 'Western Metropolitan',
  Kororoit: 'Western Metropolitan',
  Niddrie: 'Western Metropolitan',
  'St Albans': 'Western Metropolitan',
  Sunbury: 'Western Metropolitan',
  Sydenham: 'Western Metropolitan',
  Tarneit: 'Western Metropolitan',
  Werribee: 'Western Metropolitan',
  Williamstown: 'Western Metropolitan',
  Bellarine: 'Western Victoria',
  Buninyong: 'Western Victoria',
  Geelong: 'Western Victoria',
  Lara: 'Western Victoria',
  Lowan: 'Western Victoria',
  Melton: 'Western Victoria',
  Polwarth: 'Western Victoria',
  Ripon: 'Western Victoria',
  'South Barwon': 'Western Victoria',
  'South-West Coast': 'Western Victoria',
  Wendouree: 'Western Victoria',
};
export const NswStateDistricts = [
  'Albury',
  'Auburn',
  'Ballina',
  'Balmain',
  'Bankstown',
  'Barwon',
  'Bathurst',
  'Baulkham Hills',
  'Bega',
  'Blacktown',
  'Blue Mountains',
  'Cabramatta',
  'Camden',
  'Campbelltown',
  'Canterbury',
  'Castle Hill',
  'Cessnock',
  'Charlestown',
  'Clarence',
  'Coffs Harbour',
  'Coogee',
  'Cootamundra',
  'Cronulla',
  'Davidson',
  'Drummoyne',
  'Dubbo',
  'East Hills',
  'Epping',
  'Fairfield',
  'Gosford',
  'Goulburn',
  'Granville',
  'Hawkesbury',
  'Heathcote',
  'Heffron',
  'Holsworthy',
  'Hornsby',
  'Keira',
  'Kiama',
  'Kogarah',
  'Ku-ring-gai',
  'Lake Macquarie',
  'Lakemba',
  'Lane Cove',
  'Lismore',
  'Liverpool',
  'Londonderry',
  'Macquarie Fields',
  'Maitland',
  'Manly',
  'Maroubra',
  'Miranda',
  'Monaro',
  'Mount Druitt',
  'Mulgoa',
  'Murray',
  'Myall Lakes',
  'Newcastle',
  'Newtown',
  'North Shore',
  'Northern Tablelands',
  'Oatley',
  'Orange',
  'Oxley',
  'Parramatta',
  'Penrith',
  'Pittwater',
  'Port Macquarie',
  'Port Stephens',
  'Prospect',
  'Riverstone',
  'Rockdale',
  'Ryde',
  'Seven Hills',
  'Shellharbour',
  'South Coast',
  'Strathfield',
  'Summer Hill',
  'Swansea',
  'Sydney',
  'Tamworth',
  'Terrigal',
  'The Entrance',
  'Tweed',
  'Upper Hunter',
  'Vaucluse',
  'Wagga Wagga',
  'Wakehurst',
  'Wallsend',
  'Willoughby',
  'Wollondilly',
  'Wollongong',
  'Wyong',
];
export const FederalDistricts = [
  'Bean',
  'Canberra',
  'Fenner',
  'Banks',
  'Barton',
  'Bennelong',
  'Berowra',
  'Blaxland',
  'Bradfield',
  'Calare',
  'Chifley',
  'Cook',
  'Cowper',
  'Cunningham',
  'Dobell',
  'Eden-Monaro',
  'Farrer',
  'Fowler',
  'Gilmore',
  'Grayndler',
  'Greenway',
  'Hughes',
  'Hume',
  'Hunter',
  'Kingsford Smith',
  'Lindsay',
  'Lyne',
  'Macarthur',
  'Mackellar',
  'Macquarie',
  'McMahon',
  'Mitchell',
  'New England',
  'Newcastle',
  'North Sydney',
  'Page',
  'Parkes',
  'Parramatta',
  'Paterson',
  'Reid',
  'Richmond',
  'Riverina',
  'Robertson',
  'Shortland',
  'Sydney',
  'Warringah',
  'Watson',
  'Wentworth',
  'Werriwa',
  'Whitlam',
  'Lingiari',
  'Solomon',
  'Blair',
  'Bonner',
  'Bowman',
  'Brisbane',
  'Capricornia',
  'Dawson',
  'Dickson',
  'Fadden',
  'Fairfax',
  'Fisher',
  'Flynn',
  'Forde',
  'Griffith',
  'Groom',
  'Herbert',
  'Hinkler',
  'Kennedy',
  'Leichhardt',
  'Lilley',
  'Longman',
  'Maranoa',
  'McPherson',
  'Moncrieff',
  'Moreton',
  'Oxley',
  'Petrie',
  'Rankin',
  'Ryan',
  'Wide Bay',
  'Wright',
  'Adelaide',
  'Barker',
  'Boothby',
  'Grey',
  'Hindmarsh',
  'Kingston',
  'Makin',
  'Mayo',
  'Spence',
  'Sturt',
  'Bass',
  'Braddon',
  'Clark',
  'Franklin',
  'Lyons',
  'Aston',
  'Ballarat',
  'Bendigo',
  'Bruce',
  'Calwell',
  'Casey',
  'Chisholm',
  'Cooper',
  'Corangamite',
  'Corio',
  'Deakin',
  'Dunkley',
  'Flinders',
  'Fraser',
  'Gellibrand',
  'Gippsland',
  'Goldstein',
  'Gorton',
  'Hawke',
  'Higgins',
  'Holt',
  'Hotham',
  'Indi',
  'Isaacs',
  'Jagajaga',
  'Kooyong',
  'La Trobe',
  'Lalor',
  'Macnamara',
  'Mallee',
  'Maribyrnong',
  'McEwen',
  'Melbourne',
  'Menzies',
  'Monash',
  'Nicholls',
  'Scullin',
  'Wannon',
  'Wills',
  'Brand',
  'Burt',
  'Canning',
  'Cowan',
  'Curtin',
  'Durack',
  'Forrest',
  'Fremantle',
  'Hasluck',
  'Moore',
  "O'Connor",
  'Pearce',
  'Perth',
  'Swan',
  'Tangney',
];
export const FederalStates = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const FederalDistrictsByState = {
  Bean: 'ACT',
  Canberra: 'ACT',
  Fenner: 'ACT',
  Banks: 'NSW',
  Barton: 'NSW',
  Bennelong: 'NSW',
  Berowra: 'NSW',
  Blaxland: 'NSW',
  Bradfield: 'NSW',
  Calare: 'NSW',
  Chifley: 'NSW',
  Cook: 'NSW',
  Cowper: 'NSW',
  Cunningham: 'NSW',
  Dobell: 'NSW',
  'Eden-Monaro': 'NSW',
  Farrer: 'NSW',
  Fowler: 'NSW',
  Gilmore: 'NSW',
  Grayndler: 'NSW',
  Greenway: 'NSW',
  Hughes: 'NSW',
  Hume: 'NSW',
  Hunter: 'NSW',
  'Kingsford Smith': 'NSW',
  Lindsay: 'NSW',
  Lyne: 'NSW',
  Macarthur: 'NSW',
  Mackellar: 'NSW',
  Macquarie: 'NSW',
  McMahon: 'NSW',
  Mitchell: 'NSW',
  'New England': 'NSW',
  Newcastle: 'NSW',
  'North Sydney': 'NSW',
  Page: 'NSW',
  Parkes: 'NSW',
  Parramatta: 'NSW',
  Paterson: 'NSW',
  Reid: 'NSW',
  Richmond: 'NSW',
  Riverina: 'NSW',
  Robertson: 'NSW',
  Shortland: 'NSW',
  Sydney: 'NSW',
  Warringah: 'NSW',
  Watson: 'NSW',
  Wentworth: 'NSW',
  Werriwa: 'NSW',
  Whitlam: 'NSW',
  Lingiari: 'NT',
  Solomon: 'NT',
  Blair: 'QLD',
  Bonner: 'QLD',
  Bowman: 'QLD',
  Brisbane: 'QLD',
  Capricornia: 'QLD',
  Dawson: 'QLD',
  Dickson: 'QLD',
  Fadden: 'QLD',
  Fairfax: 'QLD',
  Fisher: 'QLD',
  Flynn: 'QLD',
  Forde: 'QLD',
  Griffith: 'QLD',
  Groom: 'QLD',
  Herbert: 'QLD',
  Hinkler: 'QLD',
  Kennedy: 'QLD',
  Leichhardt: 'QLD',
  Lilley: 'QLD',
  Longman: 'QLD',
  Maranoa: 'QLD',
  McPherson: 'QLD',
  Moncrieff: 'QLD',
  Moreton: 'QLD',
  Oxley: 'QLD',
  Petrie: 'QLD',
  Rankin: 'QLD',
  Ryan: 'QLD',
  'Wide Bay': 'QLD',
  Wright: 'QLD',
  Adelaide: 'SA',
  Barker: 'SA',
  Boothby: 'SA',
  Grey: 'SA',
  Hindmarsh: 'SA',
  Kingston: 'SA',
  Makin: 'SA',
  Mayo: 'SA',
  Spence: 'SA',
  Sturt: 'SA',
  Bass: 'TAS',
  Braddon: 'TAS',
  Clark: 'TAS',
  Franklin: 'TAS',
  Lyons: 'TAS',
  Aston: 'VIC',
  Ballarat: 'VIC',
  Bendigo: 'VIC',
  Bruce: 'VIC',
  Calwell: 'VIC',
  Casey: 'VIC',
  Chisholm: 'VIC',
  Cooper: 'VIC',
  Corangamite: 'VIC',
  Corio: 'VIC',
  Deakin: 'VIC',
  Dunkley: 'VIC',
  Flinders: 'VIC',
  Fraser: 'VIC',
  Gellibrand: 'VIC',
  Gippsland: 'VIC',
  Goldstein: 'VIC',
  Gorton: 'VIC',
  Hawke: 'VIC',
  Higgins: 'VIC',
  Holt: 'VIC',
  Hotham: 'VIC',
  Indi: 'VIC',
  Isaacs: 'VIC',
  Jagajaga: 'VIC',
  Kooyong: 'VIC',
  'La Trobe': 'VIC',
  Lalor: 'VIC',
  Macnamara: 'VIC',
  Mallee: 'VIC',
  Maribyrnong: 'VIC',
  McEwen: 'VIC',
  Melbourne: 'VIC',
  Menzies: 'VIC',
  Monash: 'VIC',
  Nicholls: 'VIC',
  Scullin: 'VIC',
  Wannon: 'VIC',
  Wills: 'VIC',
  Brand: 'WA',
  Burt: 'WA',
  Canning: 'WA',
  Cowan: 'WA',
  Curtin: 'WA',
  Durack: 'WA',
  Forrest: 'WA',
  Fremantle: 'WA',
  Hasluck: 'WA',
  Moore: 'WA',
  "O'Connor": 'WA',
  Pearce: 'WA',
  Perth: 'WA',
  Swan: 'WA',
  Tangney: 'WA',
};
export const QueenslandCouncilWards = [
  'Gold Coast City Division 1',
  'Gold Coast City Division 12',
  'Gold Coast City Division 7',
  'Logan City Division 6',
  'Brisbane City Bracken Ridge',
  'Brisbane City Calamvale',
  'Brisbane City Central',
  'Brisbane City Chandler',
  'Brisbane City Coorparoo',
  'Brisbane City Deagon',
  'Brisbane City Enoggera',
  'Brisbane City Forest Lake',
  'Brisbane City Hamilton',
  'Brisbane City Holland Park',
  'Brisbane City Jamboree',
  'Brisbane City MacGregor',
  'Brisbane City Marchant',
  'Brisbane City McDowall',
  'Brisbane City Moorooka',
  'Brisbane City Morningside',
  'Brisbane City Northgate',
  'Brisbane City Paddington',
  'Brisbane City Pullenvale',
  'Brisbane City Runcorn',
  'Brisbane City Tennyson',
  'Brisbane City The Gabba',
  'Brisbane City The Gap',
  'Brisbane City Walter Taylor',
  'Brisbane City Wynnum-Manly',
  'Sunshine Coast Regional Division 1',
  'Scenic Rim Regional Division 1',
  'Sunshine Coast Regional Division 10',
  'Moreton Bay Regional Division 3',
  'Sunshine Coast Regional Division 5',
  'Sunshine Coast Regional Division 9',
  'Toowoomba',
  'Ipswich',
  'Currumbin',
  'Bundamba',
];
export const QueenslandExtraBallots = ['Currumbin', 'Brisbane', 'Bundamba'];
export const QueenslandCouncilWardsToExtraBallot = {
  'Gold Coast City Division 1': 'Currumbin',
  'Gold Coast City Division 12': 'Currumbin',
  'Gold Coast City Division 7': 'Currumbin',
  'Brisbane City Bracken Ridge': 'Brisbane',
  'Brisbane City Calamvale': 'Brisbane',
  'Brisbane City Central': 'Brisbane',
  'Brisbane City Chandler': 'Brisbane',
  'Brisbane City Coorparoo': 'Brisbane',
  'Brisbane City Deagon': 'Brisbane',
  'Brisbane City Enoggera': 'Brisbane',
  'Brisbane City Forest Lake': 'Brisbane',
  'Brisbane City Hamilton': 'Brisbane',
  'Brisbane City Holland Park': 'Brisbane',
  'Brisbane City Jamboree': 'Brisbane',
  'Brisbane City MacGregor': 'Brisbane',
  'Brisbane City Marchant': 'Brisbane',
  'Brisbane City McDowall': 'Brisbane',
  'Brisbane City Moorooka': 'Brisbane',
  'Brisbane City Morningside': 'Brisbane',
  'Brisbane City Northgate': 'Brisbane',
  'Brisbane City Paddington': 'Brisbane',
  'Brisbane City Pullenvale': 'Brisbane',
  'Brisbane City Runcorn': 'Brisbane',
  'Brisbane City Tennyson': 'Brisbane',
  'Brisbane City The Gabba': 'Brisbane',
  'Brisbane City The Gap': 'Brisbane',
  'Brisbane City Walter Taylor': 'Brisbane',
  'Brisbane City Wynnum-Manly': 'Brisbane',
  Ipswich: 'Bundamba',
  Bundamba: 'Ipswich',
  Currumbin: 'Currumbin',
};

export const QueenslandStateDivisions = [
  'Algester',
  'Aspley',
  'Bancroft',
  'Barron River',
  'Bonney',
  'Broadwater',
  'Buderim',
  'Bulimba',
  'Bundaberg',
  'Bundamba',
  'Burdekin',
  'Burleigh',
  'Burnett',
  'Cairns',
  'Callide',
  'Caloundra',
  'Capalaba',
  'Chatsworth',
  'Clayfield',
  'Condamine',
  'Cook',
  'Coomera',
  'Cooper',
  'Currumbin',
  'Everton',
  'Ferny Grove',
  'Gaven',
  'Gladstone',
  'Glass House',
  'Greenslopes',
  'Gregory',
  'Gympie',
  'Hervey Bay',
  'Hill',
  'Hinchinbrook',
  'Inala',
  'Ipswich',
  'Ipswich West',
  'Jordan',
  'Kawana',
  'Keppel',
  'Kurwongbah',
  'Lockyer',
  'Logan',
  'Lytton',
  'Macalister',
  'Mackay',
  'Maiwar',
  'Mansfield',
  'Maroochydore',
  'Maryborough',
  'McConnel',
  'Mermaid Beach',
  'Miller',
  'Mirani',
  'Moggill',
  'Morayfield',
  'Mount Ommaney',
  'Mudgeeraba',
  'Mulgrave',
  'Mundingburra',
  'Murrumba',
  'Nanango',
  'Nicklin',
  'Ninderry',
  'Noosa',
  'Nudgee',
  'Oodgeroo',
  'Pine Rivers',
  'Pumicestone',
  'Redcliffe',
  'Redlands',
  'Rockhampton',
  'Sandgate',
  'Scenic Rim',
  'South Brisbane',
  'Southern Downs',
  'Southport',
  'Springwood',
  'Stafford',
  'Stretton',
  'Surfers Paradise',
  'Theodore',
  'Thuringowa',
  'Toohey',
  'Toowoomba North',
  'Toowoomba South',
  'Townsville',
  'Traeger',
  'Warrego',
  'Waterford',
  'Whitsunday',
  'Woodridge',
];

export const WaStateDistricts = [
  'Albany',
  'Armadale',
  'Balcatta',
  'Baldivis',
  'Bassendean',
  'Bateman',
  'Belmont',
  'Bicton',
  'Bunbury',
  'Burns Beach',
  'Butler',
  'Cannington',
  'Carine',
  'Central Wheatbelt',
  'Churchlands',
  'Cockburn',
  'Collie-Preston',
  'Cottesloe',
  'Darling Range',
  'Dawesville',
  'Forrestfield',
  'Fremantle',
  'Geraldton',
  'Hillarys',
  'Jandakot',
  'Joondalup',
  'Kalamunda',
  'Kalgoorlie',
  'Kimberley',
  'Kingsley',
  'Kwinana',
  'Landsdale',
  'Mandurah',
  'Maylands',
  'Midland',
  'Mirrabooka',
  'Moore',
  'Morley',
  'Mount Lawley',
  'Murray-Wellington',
  'Nedlands',
  'North West Central',
  'Perth',
  'Pilbara',
  'Riverton',
  'Rockingham',
  'Roe',
  'Scarborough',
  'South Perth',
  'Southern River',
  'Swan Hills',
  'Thornlie',
  'Vasse',
  'Victoria Park',
  'Wanneroo',
  'Warnbro',
  'Warren-Blackwood',
  'West Swan',
  'Willagee',
];

export const WaStateRegions = [
  'Agricultural',
  'East Metropolitan',
  'Mining and Pastoral',
  'North Metropolitan',
  'South Metropolitan',
  'South West',
];

export const WaStateRegionsByDistrict = {
  'Central Wheatbelt': 'Agricultural',
  Geraldton: 'Agricultural',
  Moore: 'Agricultural',
  Roe: 'Agricultural',
  Armadale: 'East Metropolitan',
  Bassendean: 'East Metropolitan',
  Belmont: 'East Metropolitan',
  'Darling Range': 'East Metropolitan',
  Forrestfield: 'East Metropolitan',
  Kalamunda: 'East Metropolitan',
  Maylands: 'East Metropolitan',
  Midland: 'East Metropolitan',
  Mirrabooka: 'East Metropolitan',
  Morley: 'East Metropolitan',
  'Mount Lawley': 'East Metropolitan',
  'Swan Hills': 'East Metropolitan',
  Thornlie: 'East Metropolitan',
  'West Swan': 'East Metropolitan',
  Kalgoorlie: 'Mining and Pastoral',
  Kimberley: 'Mining and Pastoral',
  'North West Central': 'Mining and Pastoral',
  Pilbara: 'Mining and Pastoral',
  Balcatta: 'North Metropolitan',
  'Burns Beach': 'North Metropolitan',
  Butler: 'North Metropolitan',
  Carine: 'North Metropolitan',
  Churchlands: 'North Metropolitan',
  Cottesloe: 'North Metropolitan',
  Hillarys: 'North Metropolitan',
  Joondalup: 'North Metropolitan',
  Kingsley: 'North Metropolitan',
  Landsdale: 'North Metropolitan',
  Nedlands: 'North Metropolitan',
  Perth: 'North Metropolitan',
  Scarborough: 'North Metropolitan',
  Wanneroo: 'North Metropolitan',
  Baldivis: 'South Metropolitan',
  Bateman: 'South Metropolitan',
  Bicton: 'South Metropolitan',
  Cannington: 'South Metropolitan',
  Cockburn: 'South Metropolitan',
  Fremantle: 'South Metropolitan',
  Jandakot: 'South Metropolitan',
  Kwinana: 'South Metropolitan',
  Riverton: 'South Metropolitan',
  Rockingham: 'South Metropolitan',
  'South Perth': 'South Metropolitan',
  'Southern River': 'South Metropolitan',
  'Victoria Park': 'South Metropolitan',
  Warnbro: 'South Metropolitan',
  Willagee: 'South Metropolitan',
  Albany: 'South West',
  Bunbury: 'South West',
  'Collie-Preston': 'South West',
  Dawesville: 'South West',
  Mandurah: 'South West',
  'Murray-Wellington': 'South West',
  Vasse: 'South West',
  'Warren-Blackwood': 'South West',
};

export const SaStateDistricts = [
  'Adelaide',
  'Badcoe',
  'Black',
  'Bragg',
  'Chaffey',
  'Cheltenham',
  'Colton',
  'Croydon',
  'Davenport',
  'Dunstan',
  'Elder',
  'Elizabeth',
  'Enfield',
  'Finniss',
  'Flinders',
  'Florey',
  'Frome',
  'Gibson',
  'Giles',
  'Hammond',
  'Hartley',
  'Heysen',
  'Hurtle Vale',
  'Kaurna',
  'Kavel',
  'King',
  'Lee',
  'Light',
  'Mackillop',
  'Mawson',
  'Morialta',
  'Morphett',
  'Mount Gambier',
  'Narungga',
  'Newland',
  'Playford',
  'Port Adelaide',
  'Ramsay',
  'Reynell',
  'Schubert',
  'Stuart',
  'Taylor',
  'Torrens',
  'Unley',
  'Waite',
  'West Torrens',
  'Wright',
];

/* eslint-disable react/prop-types */
const ElectorateWVG = ({
  headline, text1, text2, text3, points,
}: { headline: string; text1: string; text2: string; text3?: string; points?: { text: string; link: string }[] }) => (
  <Container>
    <h1>{headline}</h1>
    <p>{text1}</p>
    <p>{text2}</p>
    {text3 && <p>{text3}</p>}
    {points && generatePoints(points)}
    <p>
      Read more at{' '}
      <a href="https://greens.org.au/policies" target="_blank">
        https://greens.org.au/policies
      </a>
    </p>
  </Container>
);

export const FederalElectorateConfig = {
  Canberra: {
    showUpperFirst: false,
  },
  Richmond: {
    showUpperFirst: false,
  },
  Brisbane: {
    showUpperFirst: false,
  },
  Lingiari: {
    showUpperFirst: false,
  },
  Solomon: {
    showUpperFirst: false,
  },
  Griffith: {
    showUpperFirst: false,
  },
  Ryan: {
    showUpperFirst: false,
  },
  Cooper: {
    showUpperFirst: false,
  },
  Higgins: {
    showUpperFirst: false,
  },
  Kooyong: {
    showUpperFirst: false,
  },
  Macnamara: {
    showUpperFirst: false,
  },
  Melbourne: {
    showUpperFirst: false,
  },
  Wills: {
    showUpperFirst: false,
  },
};
export const QueenslandElectorateConfig = {
  'Brisbane City The Gabba': {
    showUpperFirst: false,
  },
  'Brisbane City Paddington': {
    showUpperFirst: false,
  },
  'Brisbane City Central': {
    showUpperFirst: false,
  },
  'Brisbane City Coorparoo': {
    showUpperFirst: false,
  },
  'Brisbane City Walter Taylor': {
    showUpperFirst: false,
  },
  'Brisbane City Pullenvale': {
    showUpperFirst: false,
  },
  'Logan City Division 6': {
    showUpperFirst: false,
  },
  'Sunshine Coast Regional Division 1': {
    showUpperFirst: false,
  },
  'Scenic Rim Regional Division 1': {
    showUpperFirst: false,
  },
  'Sunshine Coast Regional Division 10': {
    showUpperFirst: false,
  },
  'Moreton Bay Regional Division 3': {
    showUpperFirst: false,
  },
  'Sunshine Coast Regional Division 5': {
    showUpperFirst: false,
  },
  'Sunshine Coast Regional Division 9': {
    showUpperFirst: false,
  },
  Toowoomba: {
    showUpperFirst: false,
  },
  'Gold Coast City Division 1': {
    showUpperFirst: false,
  },
  'Gold Coast City Division 12': {
    showUpperFirst: false,
  },
  'Gold Coast City Division 7': {
    showUpperFirst: false,
  },
  Brisbane: {
    labels: {
      lowerLabel: 'Council',
      upperLabel: 'Mayor',
    },
    upperBallotHeadline: (
      <h2>
        How to vote Greens<br /> for Brisbane Mayor
      </h2>
    ),
  },
  Ipswich: {
    labels: {
      lowerLabel: 'Mayor',
      upperLabel: 'Bundamba',
    },
  },
  Currumbin: {
    labels: {
      lowerLabel: 'Council',
      upperLabel: 'Currumbin',
    },
  },
};
export const urlSafeElectorate = (electorateName: string) => electorateName.toLowerCase().replace(/[ ']/g, '-');
export const normaliseElectorate = (electorateName: string) => electorateName.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
export const findElectorate = (electorateName: string, electorateList: string[]) => electorateList[findIndex(electorateList, (el) => urlSafeElectorate(el) === urlSafeElectorate(electorateName))];
