import React from 'react';
import { Container } from 'react-bootstrap';
import { PopGrid } from '@australiangreens/components';
import ElectionIssues from '~/helpers/constants/ElectionIssues';

/**
 * Component for the 2022 federal election "Why Vote Green" section.
 */
export const Fed22WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>If just a few hundred people change their vote, we can kick the Liberals out and put the Greens in balance of power in Parliament.</p>
      <PopGrid tiles={ElectionIssues.fed22} rowSize={3} />
      <p>&nbsp;</p>
      <p>We&lsquo;ll tax billionaires and big corporations to pay for it. <a href="https://greens.org.au/platform">Read more about our plans &gt;&gt;</a>.</p>
    </div>
  </Container>
);

export default Fed22WVG;
