/** Helper functions for generating & rendering ballot papers. */
/* eslint-disable import/prefer-default-export */
import React, { Fragment, ReactElement } from 'react';
import { DISTRICT, REGION, STATE } from '~/helpers/constants/ElectorateTypes';
import { UpperBallotColours, LowerBallotColour } from '~/helpers/constants/BallotColours';
import { Config } from './types/Config';
import { Electorate, ElectorateType } from './types/Electorate';
import { Instructions } from './types/Instructions';

/**
 * Helper function to determine the colour of a ballot paper.
 * Uses election-wide lower and upper ballot colour config if provided,
 * otherwise uses colours from the BallotColours constant.
 * TODO: Refactor to remove all election-specific logic and use config.
 */
export const getBallotColour = (electorateType: ElectorateType, electorate: Electorate, config: Config): string => {
  if (config.election.code === 'nsw19') {
    return '#ffffff';
  }
  switch (electorateType) {
    case DISTRICT:
      if (config.lowerBallotColour) {
        return config.lowerBallotColour;
      }
      return LowerBallotColour;
    case REGION:
      if (config.upperBallotColour) {
        return config.upperBallotColour;
      }
      return UpperBallotColours[electorate.name];
    case STATE:
        return '#ffffff';
    default:
      return null;
  }
};

/**
 * Helper function that generates the string of 'instructions' that appear
 * at the top of the ballot.
 * TODO: Refactor to remove all election-specific logic and use config.
 */
export const getInstructionsForElection = (candidate: Instructions, electionCode: string): ReactElement => {
  if (electionCode === 'qld20') {
    if (candidate.electorate.name === 'Toowoomba') {
      return <b>Vote Greens and 9 other candidates of your choice.</b>;
    }
    return <b>Remember to number every box.</b>;
  }
  if (candidate.electorateType === DISTRICT) {
    if (electionCode === 'sa22') {
      return (
        <>
          <p>How To Vote<br />
            <strong>{candidate.firstName} {candidate.lastName}</strong><br />
            THE GREENS<br />
            House of Assembly
          </p>
          <div style={{ textTransform: 'uppercase' }}>
            District Of {candidate.electorate.name}
          </div>
        </>
      );
    }
    if (electionCode === 'wa21') {
      return (
        <>
          You must number{' '}
          <b>
            <u>EVERY</u>
          </b>{' '}
          box for your vote to count.
        </>
      );
    }
    if (electionCode === 'vic18') {
      return (
        <>
          You must number{' '}
          <b>
            <u>EVERY BOX</u>
          </b>{' '}
          for your vote to count.
          <b> No ticks or crosses.</b>
        </>
      );
    }
    if (electionCode === 'nsw19') {
      return (
        <>
          Number [1]{' '}
          <b>
            {candidate.firstName} {candidate.lastName} THE GREENS
          </b>
          <br />
          Then number the other boxes as indicated.
        </>
      );
    }
    if (electionCode === 'fed22') {
      return (
        <>
          On the green paper, put a [1] for{' '}
          <b>
            {candidate.lastName}, {candidate.firstName}
          </b>
          <br />
          then number all other boxes in order of your choice.
        </>
      );
    }
  } else {
    if (electionCode === 'sa22') {
      return (
        <>
          <p>How to vote for <strong>THE GREENS</strong> in the Legislative Council</p>
          <div>
            <p>On the large white paper <strong>PUT &apos;1&apos; IN THE BOX MARKED &apos;I&apos;</strong> above the line,
              then number the other boxes as shown. You may number more groups if you wish.
            </p>
          </div>
        </>
      );
    }
    if (electionCode === 'wa21') {
      return (
        <>
          Place <b>1</b> in THE GREENS (WA) box
        </>
      );
    }
    if (electionCode === 'vic18') {
      return (
        <>
          Vote{' '}
          <b>
            <u>ABOVE THE LINE</u>
          </b>{' '}
          by putting a 1 in the box <b>AUSTRALIAN GREENS.</b>
          <br />
          Leave all other boxes <b>BLANK.</b>
        </>
      );
    }
    if (electionCode === 'nsw19') {
      return (
        <>
          Number [1] in <b>group {candidate.group}</b> above the line<br />
          Then number the other boxes above the line as indicated<br />
          No need to number the boxes below the line.
        </>
      );
    }
    if (electionCode === 'fed22') {
      return (
        <>
          Number [1] in <b>group {candidate.group}</b> above the line<br />
          Then number five more boxes above the line, from <b>2</b> to <b>6</b> as shown here.
        </>
      );
    }
  }
  return null;
};
