/** Constants with the background colour of ballot papers. */
/* TODO: Refactor this file and merge with other colour logic. */

/** Colours of the ballot papers for the upper house in Victorian State elections. */
export const UpperBallotColours = {
  Agricultural: '#bddfb7',
  'East Metropolitan': '#daf0f6',
  'Mining and Pastoral': '#c8b5ae',
  'North Metropolitan': '#d9bfd0',
  'South Metropolitan': '#efc9ac',
  'South West': '#d0dfd9',
};

/** Colour of the ballot paper for the lower house in ??? elections. */
export const LowerBallotColour = '#F5F6FA';
