import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from '~/store';
import { Preference } from '~/helpers/types/Preference';
import { STATE, REGION, DISTRICT } from '~/helpers/constants/ElectorateTypes';
import { urlSafeElectorate } from '~/helpers/constants/Electorates';
import { Election } from '~/helpers/types/Election';
import { Config } from '~/helpers/types/Config';
import { Electorate } from '~/helpers/types/Electorate';

/** Generates a candidate-specific tagline, including a link to the candidate page. */
const generateCandidateTagline = (
  election: Election,
  { firstName, lastName, electorate }: { firstName: string; lastName: string; electorate: string },
): ReactNode => {
  let stateString = '';
  if ('state' in election) {
    stateString = `${election.state}/`;
  }
  return (
    <>
      <span>Vote 1 </span>
      <a
        href={`https://greens.org.au/${stateString}candidates/${urlSafeElectorate(electorate)}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#fff' }}
      >
        {`${firstName} ${lastName.toLowerCase()}`}
      </a>{' '}
      <br />in {electorate}
    </>
  );
};

/** Generates a party generic tagline.
 * Uses election-specific config if available,
 * or falls back to generic.
 */
const generatePartyTagline = (
  { electorateType, electorate }: { electorateType: string; electorate: string },
  { lowerBallotHeadline, upperBallotHeadline }: { lowerBallotHeadline?: ReactElement; upperBallotHeadline?: ReactElement },
): string|ReactNode => {
  if (electorateType === DISTRICT && lowerBallotHeadline) {
    return lowerBallotHeadline;
  }
  if ((electorateType === STATE || electorateType === REGION || electorateType === 'Other') && upperBallotHeadline) {
    return upperBallotHeadline;
  }
  return (
    <>
      How to vote Greens <br />in {electorateType === STATE ? 'the Upper House' : electorate}
    </>
  );
};

type HowToVoteInstructionsProps = {
  /** The preference for the Greens candidate for this ballot. */
  greensCandidate: Preference;
  /** Additional information about the candidate, if available. */
  candidateInfo?: object;
  /** The election details. */
  config: Config;
  /** The electorate this ballot relates to. */
  electorate: Electorate;
};

/**
 * Component that shows instructions on how to vote, above a rendered ballot.
 * Really more of a header than detailed instructions.
 * Ballot itself often contains instructions (as we mimic whatever is on the physical paper).
 */
export const HowToVoteInstructions: React.SFC<HowToVoteInstructionsProps> = ({
  greensCandidate,
  candidateInfo,
  config,
  electorate,
}) => {
  let candidate;
  if (candidateInfo && candidateInfo[electorate.name]) {
    candidate = {
      ...greensCandidate,
      ...candidateInfo[electorate.name],
    };
  } else {
    candidate = { ...greensCandidate };
  }
  const Instructions = styled.div`
    min-height: 50px;
  `;
  const Text = styled.h2`
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    width: 100%;
    background-color: #009949;
    font-size: 1.8rem;
  `;
  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Instructions>
        <div style={{ maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '250px',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPositionX: `${candidate.centreOfFace ? `${candidate.centreOfFace}%` : 'center'}`,
              backgroundPositionY: `${candidate.centreOfFaceY ? `${candidate.centreOfFaceY}%` : 'center'}`,
              backgroundImage: `url('${electorate.type === 'State' ? candidate.heroImage : candidate.profileImage}')`,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
          </div>
            <Text>
              <span style={{
                color: '#ffffff', display: 'inline', letterSpacing: '-0.015rem', lineHeight: '2', padding: '.5rem .5rem', boxDecorationBreak: 'clone', WebkitBoxDecorationBreak: 'clone', fontFamily: "'Anton', sans-serif", fontWeight: 300,
                textShadow: '-2.5px 2.5px 0px #007236'
              }}
              >
                {candidate.firstName && config.election.code === 'wa21'
                  ? generateCandidateTagline(config.election, candidate)
                  : generatePartyTagline(candidate, config)}
              </span>
            </Text>
        </div>
      </Instructions>
    </Container>
  );
};

HowToVoteInstructions.defaultProps = {
  candidateInfo: undefined,
};

export default connect(({ candidateInfo }) => ({ candidateInfo }))(HowToVoteInstructions);
