/**
 * Constants with the relevant 'election issues' to be displayed in the WhyVoteGreen section.
 * Somewhat deprecated, hasn't been used in recent elections, as we needed more flexibility.
 */
// vic18
// import TrainIcon from '../../assets/images/TrainIcon.png';
// import HousingIcon from '../../assets/images/HousingIcon.png';
// import SolarIcon from '../../assets/images/SolarIcon.png';
// import ForestsIcon from '../../assets/images/ForestsIcon.png';

// nsw19
// import HomesForEveryone from '../../assets/images/homes-for-everyone.jpg';
// import ClimateAction from '../../assets/images/climate-action.jpg';
// import Environment from '../../assets/images/environment.jpg';
// import PeopleNotProfit from '../../assets/images/people-not-profit.jpg';
// import PoliticalCorruption from '../../assets/images/political-corruption.jpg';
// import StrongerCommunities from '../../assets/images/stronger-communities.jpg';

// wa21

// import Wa21WVGClimate from '../../assets/images/wa21wvg-climate.jpg';
// import Wa21WVGCommunity from '../../assets/images/wa21wvg-community.jpg';
// import Wa21WVGDemocracy from '../../assets/images/wa21wvg-democracy.jpg';
// import Wa21WVGEssentials from '../../assets/images/wa21wvg-essentials.jpg';
// import Wa21WVGFirstNations from '../../assets/images/wa21wvg-firstnations.jpg';
// import Wa21WVGJobs from '../../assets/images/wa21wvg-jobs.jpg';

// sa22

// import Sa22WVGHealth from '../../assets/images/sa22wvg-health.png';
// import Sa22WVGClimate from '../../assets/images/sa22wvg-climate.png';
// import Sa22WVGHousing from '../../assets/images/sa22wvg-housing.png';
// import Sa22WVGTransport from '../../assets/images/sa22wvg-transport.png';
// import Sa22WVGServices from '../../assets/images/sa22wvg-services.png';
// import Sa22WVGDevelopers from '../../assets/images/sa22wvg-developers.png';

import Fed22WVGTreaty from '../../assets/images/htv-grid1-treaty.png';
import Fed22WVGCoal from '../../assets/images/htv-grid2-coal_gas.png';
import Fed22WVGMedicare from '../../assets/images/htv-grid3-dental_mental.png';
import Fed22WVGDebt from '../../assets/images/htv-grid4-uni_tafe.png';
import Fed22WVGHomes from '../../assets/images/htv-grid5-housing.png';
import Fed22WVGChildcare from '../../assets/images/htv-grid6-childcare.png';

// const vic18ElectionSite = 'https://victoria.greens.org.au';

// const vic18 = [
//   {
//     title: 'Transforming Transport',
//     icon: TrainIcon,
//     url: `${vic18ElectionSite}/transforming_transport`,
//   },
//   {
//     title: 'A Home For All',
//     icon: HousingIcon,
//     url: `${vic18ElectionSite}/housing`,
//   },
//   {
//     title: '100% Renewable Energy',
//     icon: SolarIcon,
//     url: `${vic18ElectionSite}/energy`,
//   },
//   {
//     title: 'Protect our Forests',
//     icon: ForestsIcon,
//     url: `${vic18ElectionSite}/forests`,
//   },
// ];

// const nsw19ElectionSite = 'https://greens.org.au/nsw';

// const nsw19 = [
//   {
//     alt: 'Homes For Everyone',
//     image: HomesForEveryone,
//     url: `${nsw19ElectionSite}/homes`,
//   },
//   {
//     alt: 'Climate Action and Renewable Energy',
//     image: ClimateAction,
//     url: `${nsw19ElectionSite}/renewables`,
//   },
//   {
//     alt: 'Tackling Political Corruption',
//     image: PoliticalCorruption,
//     url: `${nsw19ElectionSite}/democracy`,
//   },
//   {
//     alt: 'Rescue Our Environment',
//     image: Environment,
//     url: `${nsw19ElectionSite}/environment`,
//   },
//   {
//     alt: 'Creating Stronger Communities',
//     image: StrongerCommunities,
//     url: `${nsw19ElectionSite}/communities`,
//   },
//   {
//     alt: 'Providing For People, Not Profit',
//     image: PeopleNotProfit,
//     url: `${nsw19ElectionSite}/services`,
//   },
// ];

const wa21ElectionSite = 'https://greens.org.au/wa/platform';

// const wa21 = [
//   {
//     alt: 'Essentials services for all',
//     image: Wa21WVGEssentials,
//     url: `${wa21ElectionSite}/essentials`,
//   },
//   {
//     alt: 'Jobs and economic recovery',
//     image: Wa21WVGJobs,
//     url: `${wa21ElectionSite}/economy`,
//   },
//   {
//     alt: 'Our Climate Crisis plan',
//     image: Wa21WVGClimate,
//     url: `${wa21ElectionSite}/climate`,
//   },
//   {
//     alt: 'Our plan to clean up politics',
//     image: Wa21WVGDemocracy,
//     url: `${wa21ElectionSite}/democracy`,
//   },
//   {
//     alt: 'Caring for community',
//     image: Wa21WVGCommunity,
//     url: `${wa21ElectionSite}/caring`,
//   },
//   {
//     alt: 'First Nations justice, heritage and health',
//     image: Wa21WVGFirstNations,
//     url: `${wa21ElectionSite}/firstnations`,
//   },
// ];

const fed22 = [
  {
    alt: 'Truth & Treaty',
    image: Fed22WVGTreaty,
    url: 'https://greens.org.au/platform/first-nations',
  },
  {
    alt: 'Beyond Coal & Gas',
    image: Fed22WVGCoal,
    url: 'https://greens.org.au/climate-crisis',
  },
  {
    alt: 'Dental & Mental Health into Medicare',
    image: Fed22WVGMedicare,
    url: 'https://greens.org.au/cost-of-living',
  },
  {
    alt: 'Free Uni & TAFE + Wipe Student Debt',
    image: Fed22WVGDebt,
    url: 'https://greens.org.au/cost-of-living',
  },
  {
    alt: 'Build a Million New Affordable Homes',
    image: Fed22WVGHomes,
    url: 'https://greens.org.au/cost-of-living',
  },
  {
    alt: 'Free Childcare',
    image: Fed22WVGChildcare,
    url: 'https://greens.org.au/cost-of-living',
  },
];

export default {
  // vic18,
  // nsw19,
  // wa21,
  fed22,
};
