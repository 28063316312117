import React from 'react';
import styled from 'styled-components';
import { GreensLogo } from '@australiangreens/components';
import { STATE } from '../../../../../helpers/constants/ElectionTypes';

type UpperHousePreferenceProps = {
  /**
   * The group number/letter for the preference.
   */
  group: string;
  /** The party name for this entry as it appears on the ballot. */
  party: string;
  /** The numerical preference for this entry on the ballot. */
  preference: string;
  /** The type of electorate (e.g. STATE) */
  electorateType: string;
  /** Should the Greens logo be shown if this entry is for the Greens candidate?
   * (Also used to determine if an empty logo-sized spot should be present for non-Greens candidate.)
   */
  showGreensLogoOnBallot: boolean;
  /** Should the logo be shown for this entry, regardless of whether this is a Greens candidate? */
  showAllLogosOnBallot: boolean;
  /** Should this preference show a group label? */
  showGroupLabelOnBallot: boolean;
  /** Should the party name be ALL CAPS? */
  capitaliseUpperHouseParties: boolean;
  /** Location of the upper ballot logo ('above' or 'below') */
  upperBallotLogoPosition: string;
  /** Array of Greens parties names as they appear on the ballot for this election.
   * This is an array because for Federal elections,
   * different state parties may appear with differing names.
   */
  partyNameOnBallot: any[];
};

type VotingSquareProps = {
  /** The party name for this entry as it appears on the ballot. */
  party: string;
  /** Array of Greens parties names as they appear on the ballot for this election.
   * This is an array because for Federal elections,
   * different state parties may appear with differing names.
   */
  partyNameOnBallot: any[];
}

/**
 * A preference entry on an upper house ballot.
 * Depending on the type of upper house ballot, there may only be
 * one (Greens) or multiple.
 * Big and messy, needs to be refactored.
 */
const UpperHousePreference: React.SFC<UpperHousePreferenceProps> = ({
  group,
  party,
  preference,
  electorateType,
  showGreensLogoOnBallot,
  showAllLogosOnBallot,
  showGroupLabelOnBallot,
  capitaliseUpperHouseParties,
  upperBallotLogoPosition,
  partyNameOnBallot,
}) => {
  const multiplePrefs = electorateType === STATE;
  const Divider = styled.div`
    width: 100%;
    border-bottom: 10px solid black;
    margin-top: 30px;
  `;
  /* TODO: Split out voting square into component */
  const VotingSquare = styled.div<VotingSquareProps>`
    border: 4px solid black;
    max-width: 80px;
    height: 80px;
    text-align: center;
    font-size: 48px;
    line-height: 80px;
    margin-top: 2px;
    background-color: white;
    color: ${props => props.partyNameOnBallot.includes(props.party) ? '#00A651' : 'black'};
    margin-bottom: 10px;
    ${multiplePrefs && 'margin-left: auto; margin-right: auto;'} font-weight: 800;
  `;
  const Candidate = styled.span`
    ${!multiplePrefs && 'font-weight: 800;'} color: black;
    ${multiplePrefs ? 'font-size: 12px;' : 'font-size: 18px;'} ${multiplePrefs
        && 'text-align: center; margin-left: auto; margin-right: auto;'};
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    width: 100%;
  `;
  const Group = styled.div`
    font-size: ${multiplePrefs ? '28px' : '32px'};
    text-align: ${showGroupLabelOnBallot ? 'center' : 'left'};
    ${!multiplePrefs && 'text-transform: uppercase;'} ${multiplePrefs ? 'font-weight: bold;' : ''} color: black;
    ${multiplePrefs && showGroupLabelOnBallot && 'margin-left: auto; margin-right: auto;'};
  `;
  const PrefContainer = styled.div.attrs({ className: 'container' })`
    margin-bottom: 10px;
    padding-left: ${multiplePrefs ? '10px' : '50px'};
    ${multiplePrefs ? 'border: 3px solid black;' : ''} min-height: 100%;
    max-width: 200px;
  `;
  const partyName = capitaliseUpperHouseParties ? party.toUpperCase() : party;
  return (
    <>
      <PrefContainer>
        <>
          <Group>
            {showGroupLabelOnBallot && 'Group'} {group}
          </Group>
        </>
        {showGreensLogoOnBallot
          && upperBallotLogoPosition === 'above' && (
            <div style={{ height: '50px' }}>
              {partyNameOnBallot.includes(party) || showAllLogosOnBallot ? <GreensLogo width="55px" height="55px" bw /> : null}
            </div>
        )}
        <div style={{ textAlign: 'center' }}>
          <VotingSquare party={party} partyNameOnBallot={partyNameOnBallot}>{preference}</VotingSquare>
        </div>
        {showGreensLogoOnBallot
          && (!upperBallotLogoPosition || upperBallotLogoPosition === 'below') && (
            <div style={{ height: '50px' }}>
              {partyNameOnBallot.includes(party) || showAllLogosOnBallot ? <GreensLogo width="55px" height="55px" bw /> : null}
            </div>
        )}
        <>
          <Candidate>{partyName.replace('/', '/​') /* Add zero-width space after slash to allow it to break gracefully */}</Candidate>
        </>
      </PrefContainer>
      {!multiplePrefs && <Divider />}
    </>
  );
};

export default UpperHousePreference;
