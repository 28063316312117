import React from 'react';
import ContentLoader from 'react-content-loader';
import { LoadingIcon } from '@australiangreens/components';
import withElection from '~/helpers/providers/withElection';
import withBooths from '~/helpers/providers/withBooths';
import withLocation from '~/helpers/providers/withLocation';
import withElectorates from '~/helpers/providers/withElectorates';
import { ElectorateWithBooths } from '~/helpers/types/Booth';
import { Location } from '~/helpers/types/Location';
import { Election } from '~/helpers/types/Election';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import BoothMap from '~/components/sections/WhereToVote/BoothMap/BoothMap';
import ErrorBoundary from '~/components/wrappers/ErrorBoundary/ErrorBoundary';
import { actions } from '~/store';

/** Unused component to make a fancy react-content-loader loading icon. */
const WhereToVoteLoader = <ContentLoader style={{ height: '70vh', width: '100%' }} height="1000" width="1000" speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" />;

type WhereToVoteProps = {
  /** Details of the user's location, from device geolocation, if available. */
  userLocation?: Location;
  /** Booths for the lower house electorate, if available. */
  booths?: ElectorateWithBooths;
  /** Details of the election. */
  election: Election;
  /** The current electorates of the user, if available. */
  electorates?: SelectedElectorates;
};

/**
 * The 'where to vote' section of the app.
 * Renders a booth map if booths are available,
 * otherwise requests booths for the electorate and shows a loading icon.
 */
export const WhereToVote: React.SFC<WhereToVoteProps> = ({
  election, booths, userLocation, electorates,
}) => {
  if (!booths && electorates) {
    actions.getBooths(electorates.lower);
  }
  return <ErrorBoundary>{booths ? <BoothMap election={election} booths={booths} userLocation={userLocation} /> : <div style={{textAlign: 'center'}}><LoadingIcon /></div>}</ErrorBoundary>;
};

WhereToVote.defaultProps = {
  booths: null,
  userLocation: null,
  electorates: null,
};

export default withElectorates(withElection(withBooths(withLocation(WhereToVote))));
