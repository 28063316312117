import { ReactElement } from 'react';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import { connect } from '~/store';
import { Config } from '../types/Config';

/** Store helper: provides the preferences for the user's currently selected electorates */
export default (component: any): (props: any) => any => (
  connect(({ preferences, electorates, config }: { preferences: object; electorates: SelectedElectorates; config: Config; }) => ((electorates && preferences)
    ? {
      preferences: {
        lower: preferences[electorates.lower.name],
        upper: (electorates.upper ? (
          config.upperBallotOverrides && config.upperBallotOverrides[electorates.lower.name] ? preferences[config.upperBallotOverrides[electorates.lower.name]] : preferences[electorates.upper.name])
          : undefined),
      },
    }
    : {
      preferences: null,
    }
  ))(component)
);
