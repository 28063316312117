import { ReactElement } from 'react';
import { connect } from '~/store';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import { GroupedBooths } from '~/helpers/types/Booth';

/** Store helper: provides prop of booths for the user's currently selected lower electorate. */
export default (component: any): (props: any) => any => (
  connect(({ electorates, booths }: { electorates: SelectedElectorates; booths: GroupedBooths}) => (
    { booths: (electorates && electorates.lower && booths && booths[electorates.lower.name]) || undefined }
  ))(component)
);
